import React from 'react';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import logo from './assets/logo/thinksoft.png';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div>
            <img className='logo' alt='logo' src={logo} />
          </div>
          <div className="social">
            <a href="https://www.instagram.com/thinksoft22/" title="Instagram" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" height={25} width={25}/>
            </a>
            <a href="https://twitter.com/ThinkSoft22" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" height={25} width={25} />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Website coming soon...</h1>
            <p>ThinkSoft is a software development company building reliable solutions for businesses and startups. It is a place where innovation and automation are working together to build a better future for people and businesses.</p>
          </div>
          <a href="mailto:info@thinksoft.gr">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span>@ {new Date().getFullYear()} Thinksoft</span>
        </div>
      </div>
    );
  }
}

export default App;
